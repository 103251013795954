import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const Index = ({ data }) => {
  const htmlData = []

  data.publicPosts.edges.forEach(({ node }) => {
    htmlData.push(
      <div key={node.id}>
        <Link to={node.fields.slug}>
          {" "}
          {node.fields.slug} {node.frontmatter.title}
        </Link>
      </div>
    )
  })
  return <Layout>{htmlData}</Layout>
}

export default Index

export const query = graphql`
  {
    publicPosts: allMarkdownRemark(filter: {frontmatter: {published: {eq: true}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            Tags
            categories
            date
          }
          timeToRead
        }
      }
    }
  }
`
